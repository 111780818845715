@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
    sans-serif !important ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiOutlinedInput-root {
  height: 44px !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #efefef;
}

::-webkit-scrollbar-thumb {
  background: rgb(179, 179, 179);
  border-radius: 100vh;
  border: 2px solid #efefef;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(163, 163, 163);
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}

button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin {
  background-image: linear-gradient(
    310deg,
    rgb(33, 82, 255),
    rgb(33, 212, 253)
  );
}

.css-1nmdiq5-menu {
  z-index: 9999 !important;
}

.modernWay_truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: black;
  color: white;
  border-color: black;
  font-size: 10px;
  padding: 6px 8px;
  border-radius: 4px;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  display: none;
  z-index: 999;
}

.tooltip-content {
  margin-bottom: 1px;
}

.tooltip-arrow {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid black;
}

.tooltip-container:hover .tooltip {
  display: block;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  right: 30px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.ant-picker-focused {
  border-color: #20a7c9 !important;
  box-shadow: none !important;
}

.ant-picker:hover {
  border-color: #20a7c9 !important;
  box-shadow: none !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: #20a7c9 !important;
}

.ant-picker {
  border-radius: 4px;
  max-width: 300px;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #20a7c9;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #20a7c9;
}
.box-shadow:hover {
  box-shadow: rgb(178, 178, 178) 8px 8px 28px 0px;
  transition: box-shadow 0.3s ease-in-out 0s;
}
.ant-modal-mask {
  opacity: 0.25 !important;
  background: #000 !important;
}

.ant-modal-content {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}
